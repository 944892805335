import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody,	Modal, } from '../../components/layouts'


import {
	Button,
	Image,
	Input,
	Loader
} from '../../components/ui'

import {
	linkBank, resetBankState, setMonthlyMin
} from '../../factory'

import Layout from '../../components/layout'

import {getUrlVars} from '../../util'
import { navigate } from '@reach/router'

import theme from '../../theme/content'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			has_chargable_accounts: false,
			showError: false,
			monthly_min_donation_limit: 10
		}
	}

	componentDidMount() {
		this.props.resetBankState()
	}


	handleOnExit() {
		// handle the case when your user exits Link
	}

	render() {
		return (
			<Layout>
				<Container>
					<PhoneBody title={"Monthly Minimum "}>
						<Flex column style={{alignItems: 'center'}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2.png"}
								width={'125px'}
								marginTop={'25px'}
								height={'auto'}
								marginBottom={"0px"}
							/>
							<Heading as="h1" fontSize={'22px'} textAlign={'center'} maxWidth={350} marginLeft={10} marginRight={10}>Set your Minimum Monthly Donation</Heading>
							<Modal
								link={{text: 'Want to learn more?', style: {color: theme.colors.dustGray}}}
							>
								<Paragraph style={{marginRight: 50}}>For example, your montly minimum is $5, but you have only rounded up $4.50 this month. Then at the end of the month, you would donate another $0.50.</Paragraph>
							</Modal>
							<Flex row style={{width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
								<div style={{height: '2em', width: '50%', marginBottom: 25}}>
									<Input
										label={'$ Amount'}
										name={'monthly_min_donation_limit'}
										field={'default'}
										placeholder={'$100'}
										type={'text'}
										maxWidth={'400px'}
										value={this.state.monthly_min_donation_limit}
										setValues={(e) => this.setState({monthly_min_donation_limit: e.target.value})}
									/>
								</div>
							</Flex>
							<Button onClick={() => this.props.setMonthlyMin(this.state.monthly_min_donation_limit, this.props.token, ()=>navigate('/register/fundingSource/'))}
								style={{marginTop: 20, maxWidth: 300}}>Set My Monthly Minimum</Button>
						</Flex>
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	bank_pending: PropTypes.bool,
	error: PropTypes.string,
	resetBankState: PropTypes.string,
	setMonthlyMin: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		bank_pending: state.bankState.bank_pending,
		error: state.bankState.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkBank: (plaid_token, token, onSuccess, onErrorCallback) => dispatch(linkBank(plaid_token, token, onSuccess, onErrorCallback)),
		resetBankState: () => dispatch(resetBankState()),
		setMonthlyMin: (min, token, callback) => dispatch(setMonthlyMin(min, token,  callback))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
